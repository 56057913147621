.ep-lic-verbiage {
  width: 82%;
  margin: 0 auto;
}

.ep-lic-container{
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.redlink {
  color: red;
  background-color: transparent;
  text-decoration: none;
}

.boldtext {
  font-weight: 1000;
}