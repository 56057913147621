.formLineTempLicense {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;

}

.formLineTempLicense > select {
  margin-left: 10px;
  margin-right: 10px;
  width: 384px;
  height: 30px;
}

.formLineTempLicense > input {
  margin-left: 10px;
  margin-right: 10px;
  width: 50px;
  height: 20px;
}

.usersPar {
  margin-left: 5px !important;
  margin-right: 10px !important;
}

.formLineTempLicense > p {
  margin: 0px 0px;
}

.formLineTempLicense > button {
  height: 25px;
  margin-top: 0px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}


#selectDays {
  width: 60px;
}

.postContainer {
  width: 82%;
  margin: 0 auto;
}

.licensesContainer {
  width: 82%;
  margin: 0 auto;
}
