.dataTableContainer {
  min-height: 70vh;
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.ep-tabs {
  width: 85%;
  height: 35px;
  align-self: center;
  margin-bottom: 10px;
}

.ep-tabs button {
  cursor: pointer;
}

.ep-container {
  display: flex;
  justify-content: center;
}

.search-box {
  margin-bottom: 15px;
  margin-top: 6px;
  margin-left: 7.5%;
  height: 20px;
  padding: 5px;
  border-radius: 4px;
  width: 40%;
}

.groupsHeader {
  margin-bottom: 15px !important;
  margin-top: 6px !important;
  height: 20px !important;
  line-height: 20px;
  padding: 5px !important;
  border-radius: 4px;
  width: 33%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.emailButtonContainer {
  height: 28px;
}

.emailButtonContainer > button {
    float: right;
    right: 9%;
    top: 90px;
    position: relative;
}

.loadingContainer {
  width: 200px;
  height: 200px;
}

.on-tab {
  width: 33.33%;
  background-color: #7c3b96;
  border: 1px solid #7c3b96;
  color: white !important;
  height: 35px;
}

.off-tab {
  width: 33.33%;
  height: 30px;
  background-color: #9499ac;
  border: 1px solid #6d738d;
  color: white;
  margin-top: 9px;
}

.on-tab:hover{
  color: #cbbce8 !important;
}

.off-tab:hover{
  color: #e5ddf3;
}

.offBtn {
  background-color: #7e57c5;
  border: 1px solid #7e57c5;
  color: white;
  border-radius: 24px;
  box-shadow: 1px 2px 3px grey;
  height: 28px;
}

.offBtn:hover {
  color: #e5ddf3;
  cursor: pointer;
}

.ReactTable {
  color: #000 !important;
  width: 85%;
  margin: 0 auto;
}

.ReactTable.-striped .rt-tr.-odd {
  background:#dbc8ff !important;
}

.ReactTable.-striped .rt-tr.-even {
  background:#f1eff4 !important;
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid #fff !important;
  border-bottom: 1px solid #fff !important;
  white-space: normal !important;
  height: 100px;
  padding-top: 15px;
  padding-left: 10px;
}

.case-support {
  cursor: pointer;
}

.case-support .rt-tr:hover {
  filter: brightness(85%);
}

.ReactTable .rt-thead .rt-th {
  height: 40px;
  line-height: 24px !important;
}

.ReactTable .rt-thead .rt-th.rt-resizable-header {
  height: 30px;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer {
  background-image: url('../assets/sort.svg');
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: 98% 8px;
}
