.inputNumberContainer  > button {
  height: 20px;
  margin-top: 0px;
  width: 20px;
  background-color: #3c89fe;
}

.inputNumberContainer > input {
  margin-left: 0px;
  margin-right: 0px;
  width: 50px;
  height: 20px;
  text-align: center;
}
