.modalContainer {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

h1, h4 {
  justify-content: left;
  align-items: left;
  width: 100%;
  margin-left: 0;
}

input{
  width: 70%;
}

b {
  float: left;
  width: 100%;
}

.modalOff {
  display: none
}

.tpModalContent {
  width: 100%;
}

.tpModalContent > p, .tpModalContent > h2 {
  margin: 0 0 15px 0 !important;
}

.infoContainer {
  width: 50%;
  max-width: 600px;
  max-height: 90%;
  padding: 2em;
  border-radius: 5px;
  border: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

.formContainer {
  width: 100%;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  margin: 1em;
}

.formLine {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}

.formLine-email {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.formLine-eula {
  width: 100%;
  flex-direction: row;
  margin: 0px;
}

.eula-input {
  width: 50px;
}

.tpCatLbl {
  min-width: 7.5em;
  word-wrap: break-word;
  font-family: Helvetica-Neue-Bold;
}

.tpCatDesc {
  width: 70%;
  min-width: 6em;
  word-wrap: break-word;
  margin: auto;
}

.email-input {
  width: 350px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tpBtnsRow {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile.disabled {
  cursor: auto;
}

.tpLbl {
  background-color: #7c62b6;
  border: none;
  color: white !important;
  padding: 10px 0 0 0;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  box-shadow: 1px 2px 3px grey;
  border-radius: 25px;
  box-shadow: 1px 2px 3px grey;
  height: 30px;
  width: 180px;
}

.tpLbl:hover{
  background-color: #36b15b;
  border: #65af5b;
  color: #fff;
}

.tpBtn {
  background-color: #7c62b6;
  border: none;
  color: white !important;
  padding: 0px !important;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  box-shadow: 1px 2px 3px grey;
  border-radius: 25px;
  box-shadow: 1px 2px 3px grey;
  height: 40px;
  width: 180px;
}

.tpBtn-disabled {
  background-color: grey;
  border: none;
  color: white !important;
  padding: 0px !important;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 25px;
  box-shadow: 1px 2px 3px grey;
  box-shadow: 1px 2px 3px grey;
  height: 40px;
  width: 150px;
}

.ep-stripe-modal {
  height: 8px;
  background: #36b15b;
  width: 100%;
  margin-bottom: 10px;
}

.tpModalBtn {
  flex: 0 1 auto;
}

.tpBtn:hover{
  background-color: #36b15b;
  border: #65af5b;
  color: #fff;
}

@media screen and (max-width: 960px) {
  .infoContainer {
    width: 60%;
  }
}

  @media screen and (max-width: 600px) {
  .infoContainer {
    width: 90%;
  }
}
