
body, html {
  margin: 0;
  padding: 0;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

h1 {
  font-weight: bold !important;
  font-size: 28px;
  color: #6172b6 !important;
}

h2 {
  font-size: 2.3em !important;
  font-weight: 300;
}

h3 {
  font-size: 1.7em !important;
  font-weight: 300;
}

p {
  color: black;
  line-height: 24px;
}

img {
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  margin: 15px;
  color: #6172b6 !important;
}

a {
  text-decoration: none !important;
}

button {
  font-size: 16px;
  outline: none;
}

input {
  font-size: 16px;
}

input:focus, textarea:focus {
  outline: none;
}

button, input {
  font-family: Montserrat, Arial, Helvetica, sans-serif;
}

#root, html, body {
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font: 16px !important;
  box-sizing: border-box;
  background-color: white !important;
  padding: 0px;
  margin: 0px;
}

#ep-link {
  color: #7e57c5 !important;
  cursor: pointer  !important;
}

#ep-link:hover {
  color: magenta !important;
  text-decoration: underline !important;
}

.ep-body-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.ep-content-container {
  flex: 10 1 auto;
}

@font-face {
  font-family: "Montserrat";
  src: url('../src/assets/montserrat/Montserrat-Regular.ttf') format('truetype'),
       url('../src/assets/montserrat/Montserrat-Regular.woff2') format('woff2');
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url('../src/assets/montserrat/Montserrat-Bold.ttf') format('truetype'),
       url('../src/assets/montserrat/Montserrat-Regular.woff2') format('woff2');
}

blockquote {
 background-color:rgba(255,229,100,0.3);
 border-left-color:#ffe564;
 border-left-width:9px;
 border-left-style:solid;
 padding:20px 45px 20px 26px;
 margin-bottom:30px;
 margin-top:20px;
 margin-left:-30px;
 margin-right:-30px;
}

blockquote p {
 margin-top:15px;
}

blockquote p:first-of-type {
 font-weight:700;
 margin-top:0;
}

blockquote p:nth-of-type(2) {
 margin-top:0;
}