.ep-footer {
  flex: 0 1 auto;
  color: white;
  background: black;
  height: 51px;
  line-height: 51px;
  padding-left: 71px;
}

@media all and (max-width: 600px) {
  .ep-footer {
    padding-left: 15px;
    height: 36px;
    line-height: 36px;
    font-size: .8em;
  }
}