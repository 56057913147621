.tpBtnAtt:hover {
  background-color: #36b15b;
  border: #65af5b;
  color: #fff;
}

.tpBtnAtt {
  height: 23px;
  width: 23px;
  font-size: 12px !important;
  color: white !important;
  background-color: #7c62b6;
  border: none;
  border-radius: 25px;
  padding: 0px !important;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  box-shadow: 1px 2px 3px grey;
}