.nfDiv > h2 {
  text-align: center;
}

.nfDiv > h3 {
  text-align: center;
}

.nfDiv > a {
  text-align: center;
}

.nfDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
