.spinner {
  display: flex;
  align-items: center;
}

.spinnerImage {
  height: 110px;
  width: 150px;
  margin-top: -35px;
  margin-left: -45px;
}

.spinnerText {
  color: white;
  margin-left: -40px;
  margin-top: -25px;
}
