
body, html {
  margin: 0;
  padding: 0;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

h1 {
  font-weight: bold !important;
  font-size: 28px;
  color: #6172b6 !important;
}

h2 {
  font-size: 2.3em !important;
  font-weight: 300;
}

h3 {
  font-size: 1.7em !important;
  font-weight: 300;
}

p {
  color: black;
  line-height: 24px;
}

img {
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  margin: 15px;
  color: #6172b6 !important;
}

a {
  text-decoration: none !important;
}

button {
  font-size: 16px;
  outline: none;
}

input {
  font-size: 16px;
}

input:focus, textarea:focus {
  outline: none;
}

button, input {
  font-family: Montserrat, Arial, Helvetica, sans-serif;
}

#root, html, body {
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font: 16px !important;
  box-sizing: border-box;
  background-color: white !important;
  padding: 0px;
  margin: 0px;
}

#ep-link {
  color: #7e57c5 !important;
  cursor: pointer  !important;
}

#ep-link:hover {
  color: magenta !important;
  text-decoration: underline !important;
}

.ep-body-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.ep-content-container {
  flex: 10 1 auto;
}

@font-face {
  font-family: "Montserrat";
  src: url(/static/media/Montserrat-Regular.9c460951.ttf) format('truetype'),
       url(/static/media/Montserrat-Regular.c38a9d8c.woff2) format('woff2');
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url(/static/media/Montserrat-Bold.88932dad.ttf) format('truetype'),
       url(/static/media/Montserrat-Regular.c38a9d8c.woff2) format('woff2');
}

blockquote {
 background-color:rgba(255,229,100,0.3);
 border-left-color:#ffe564;
 border-left-width:9px;
 border-left-style:solid;
 padding:20px 45px 20px 26px;
 margin-bottom:30px;
 margin-top:20px;
 margin-left:-30px;
 margin-right:-30px;
}

blockquote p {
 margin-top:15px;
}

blockquote p:first-of-type {
 font-weight:700;
 margin-top:0;
}

blockquote p:nth-of-type(2) {
 margin-top:0;
}
.ep-menu {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: row;
}

.ep-menu-item {
  display: inline-block;
  align-self: flex-end;
  text-decoration: none;
  padding: 0 1em 1.5em 1em;
  outline: none;
}

.ep-menu-item > button {
  font-weight: normal;
  font-size: 16px;
}

.ep-menu-item > button:hover {
  color: #3f2b56 !important;
}

@media all and (max-width: 960px) {
  .ep-menu {
    justify-content: flex-start;
  }

  .ep-menu-item {
    padding: 1em 1em 1em 1em;
  }
}

@media all and (max-width: 600px) {
  .ep-menu {
    flex-direction: column;
    padding-bottom: 15px;
  }

  .ep-menu-item {
    padding: 0;
    align-self: flex-start;
  }
}

.modalContainer {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  transition: all 0.25s ease-in-out;
}

h1, h4 {
  justify-content: left;
  align-items: left;
  width: 100%;
  margin-left: 0;
}

input{
  width: 70%;
}

b {
  float: left;
  width: 100%;
}

.modalOff {
  display: none
}

.tpModalContent {
  width: 100%;
}

.tpModalContent > p, .tpModalContent > h2 {
  margin: 0 0 15px 0 !important;
}

.infoContainer {
  width: 50%;
  max-width: 600px;
  max-height: 90%;
  padding: 2em;
  border-radius: 5px;
  border: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

.formContainer {
  width: 100%;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  margin: 1em;
}

.formLine {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}

.formLine-email {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.formLine-eula {
  width: 100%;
  flex-direction: row;
  margin: 0px;
}

.eula-input {
  width: 50px;
}

.tpCatLbl {
  min-width: 7.5em;
  word-wrap: break-word;
  font-family: Helvetica-Neue-Bold;
}

.tpCatDesc {
  width: 70%;
  min-width: 6em;
  word-wrap: break-word;
  margin: auto;
}

.email-input {
  width: 350px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tpBtnsRow {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile.disabled {
  cursor: auto;
}

.tpLbl {
  background-color: #7c62b6;
  border: none;
  color: white !important;
  padding: 10px 0 0 0;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  box-shadow: 1px 2px 3px grey;
  border-radius: 25px;
  box-shadow: 1px 2px 3px grey;
  height: 30px;
  width: 180px;
}

.tpLbl:hover{
  background-color: #36b15b;
  border: #65af5b;
  color: #fff;
}

.tpBtn {
  background-color: #7c62b6;
  border: none;
  color: white !important;
  padding: 0px !important;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  box-shadow: 1px 2px 3px grey;
  border-radius: 25px;
  box-shadow: 1px 2px 3px grey;
  height: 40px;
  width: 180px;
}

.tpBtn-disabled {
  background-color: grey;
  border: none;
  color: white !important;
  padding: 0px !important;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 25px;
  box-shadow: 1px 2px 3px grey;
  box-shadow: 1px 2px 3px grey;
  height: 40px;
  width: 150px;
}

.ep-stripe-modal {
  height: 8px;
  background: #36b15b;
  width: 100%;
  margin-bottom: 10px;
}

.tpModalBtn {
  flex: 0 1 auto;
}

.tpBtn:hover{
  background-color: #36b15b;
  border: #65af5b;
  color: #fff;
}

@media screen and (max-width: 960px) {
  .infoContainer {
    width: 60%;
  }
}

  @media screen and (max-width: 600px) {
  .infoContainer {
    width: 90%;
  }
}


.tipText {
  color: white;
}

.tpTip {
  margin-top: 0px;
  margin-bottom: 0px;
}

.tpBtnTip:hover {
  background-color: #36b15b;
  border: #65af5b;
  color: #fff;
}

.tpBtnTip {
  height: 23px;
  width: 115px;
  font-size: 12px !important;
  color: white !important;
  background-color: #7c62b6;
  border: none;
  border-radius: 25px;
  padding: 0px !important;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  box-shadow: 1px 2px 3px grey;
}

.formLineHostID {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 0px;
}

@font-face {
  font-family: myNexaBoldFont;
  src: url(/static/media/NexaBold.c9f309b3.otf);
}

.ep-title {
  width: 100%;
  max-width: 1500px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ep-title > button {
  font-family: myNexaBoldFont;
  font-size: 38px;
  text-align: center;
  margin-left: 60px;
  margin-top: 14px;
  font-weight: bold;
  color: #6172b6;
  border: none;
  background: none;
  cursor: pointer;
}

.ep-header {
  flex: 0 1 auto;
}

.ep-header-grid {
  padding: 0 71px;
  display: flex;
  align-items: center;
  height: 120px;
}

.ep-logo-container {
  width: 200px;
  min-width: 200px;
}

.ep-stripe {
  height: 8px;
  background: #36b15b;
  width: 100vw;
  margin-bottom: 90px;
}

.ep-menu-container {
  display: flex;
  align-items: center;
  align-self: flex-end;
  margin-left: 5%;
}

.ep-welcome {
  display: inline-block;
  text-align: center;
  align-self: flex-end;
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 4;
  grid-column-end: 4;
  color: #7e57c5;
  padding-bottom: 1.5em;
  margin-left: 4%;
}

.ep-footer {
  flex: 0 1 auto;
  color: white;
  background: black;
  height: 51px;
  line-height: 51px;
  padding-left: 71px;
}

@media all and (max-width: 600px) {
  .ep-footer {
    padding-left: 15px;
    height: 36px;
    line-height: 36px;
    font-size: .8em;
  }
}
.dataTableContainer {
  min-height: 70vh;
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.ep-tabs {
  width: 85%;
  height: 35px;
  align-self: center;
  margin-bottom: 10px;
}

.ep-tabs button {
  cursor: pointer;
}

.ep-container {
  display: flex;
  justify-content: center;
}

.search-box {
  margin-bottom: 15px;
  margin-top: 6px;
  margin-left: 7.5%;
  height: 20px;
  padding: 5px;
  border-radius: 4px;
  width: 40%;
}

.groupsHeader {
  margin-bottom: 15px !important;
  margin-top: 6px !important;
  height: 20px !important;
  line-height: 20px;
  padding: 5px !important;
  border-radius: 4px;
  width: 33%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.emailButtonContainer {
  height: 28px;
}

.emailButtonContainer > button {
    float: right;
    right: 9%;
    top: 90px;
    position: relative;
}

.loadingContainer {
  width: 200px;
  height: 200px;
}

.on-tab {
  width: 33.33%;
  background-color: #7c3b96;
  border: 1px solid #7c3b96;
  color: white !important;
  height: 35px;
}

.off-tab {
  width: 33.33%;
  height: 30px;
  background-color: #9499ac;
  border: 1px solid #6d738d;
  color: white;
  margin-top: 9px;
}

.on-tab:hover{
  color: #cbbce8 !important;
}

.off-tab:hover{
  color: #e5ddf3;
}

.offBtn {
  background-color: #7e57c5;
  border: 1px solid #7e57c5;
  color: white;
  border-radius: 24px;
  box-shadow: 1px 2px 3px grey;
  height: 28px;
}

.offBtn:hover {
  color: #e5ddf3;
  cursor: pointer;
}

.ReactTable {
  color: #000 !important;
  width: 85%;
  margin: 0 auto;
}

.ReactTable.-striped .rt-tr.-odd {
  background:#dbc8ff !important;
}

.ReactTable.-striped .rt-tr.-even {
  background:#f1eff4 !important;
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid #fff !important;
  border-bottom: 1px solid #fff !important;
  white-space: normal !important;
  height: 100px;
  padding-top: 15px;
  padding-left: 10px;
}

.case-support {
  cursor: pointer;
}

.case-support .rt-tr:hover {
  filter: brightness(85%);
}

.ReactTable .rt-thead .rt-th {
  height: 40px;
  line-height: 24px !important;
}

.ReactTable .rt-thead .rt-th.rt-resizable-header {
  height: 30px;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer {
  background-image: url(/static/media/sort.3f5e12b1.svg);
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: 98% 8px;
}

.spinner {
  display: flex;
  align-items: center;
}

.spinnerImage {
  height: 110px;
  width: 150px;
  margin-top: -35px;
  margin-left: -45px;
}

.spinnerText {
  color: white;
  margin-left: -40px;
  margin-top: -25px;
}

.inputNumberContainer  > button {
  height: 20px;
  margin-top: 0px;
  width: 20px;
  background-color: #3c89fe;
}

.inputNumberContainer > input {
  margin-left: 0px;
  margin-right: 0px;
  width: 50px;
  height: 20px;
  text-align: center;
}

.tpBtnAtt:hover {
  background-color: #36b15b;
  border: #65af5b;
  color: #fff;
}

.tpBtnAtt {
  height: 23px;
  width: 23px;
  font-size: 12px !important;
  color: white !important;
  background-color: #7c62b6;
  border: none;
  border-radius: 25px;
  padding: 0px !important;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  box-shadow: 1px 2px 3px grey;
}
form > a {
  margin:15px;
}

.ep-login-grid {
  padding: 0 15px 0 15px;
  display: grid;
  grid-template-columns: auto minmax(-webkit-min-content, 683px) minmax(-webkit-min-content, 683px) auto;
  grid-template-columns: auto minmax(min-content, 683px) minmax(min-content, 683px) auto;
  grid-template-rows: auto;
}

.forgot-pwd-button {
  grid-row-start: 2;
  grid-row-end: 2;
  grid-column-start: 3;
  grid-column-end: 3;
  padding: 0 56px 0 56px;
}

.ep-login-vbge {
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 2;
  grid-column-end: 2;
  padding: 0 56px 0 56px;
}

.ep-login-vbge > p, .ep-login-vbge > h2, .ep-login-frm > h3 {
  margin: 15px 0 4px 0px !important;
}

.ep-login-frm {
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 3;
  grid-column-end: 3;
  padding: 0 56px 0 56px;
}

.ep-login-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  margin-top: 15px;
}

.ep-login-item {
  flex: 0 1 auto;
  padding: 0;
  width: 70%;
}

.ep-login-item > p, .ep-login-item > input {
  margin: 5px 0 5px 0px !important;
}

.ep-login-input {
  width: 96%;
  font-size: 14px;
}

.ep-login-btn {
  display: 'flex';
  font-size: 14px;
  box-shadow: 1px 2px 3px grey;
  width: 157px;
}

.ep-login-frm > p {
  margin-left: 138px !important;
  font-size: 12px !important;
}

.fpb, .loginBtn {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 70%;
    margin-top: 15px;
}

@media screen and (max-width: 960px) {
    .ep-login-item > p, .ep-login-item > input {
    margin: 0px 0 8px 0px !important;
  }
  .ep-login-vbge {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .ep-login-frm {
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .forgot-pwd-button {
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .ep-login-row{
    flex-direction: column;
    width: 80%;
  }

  .ep-login-input {
    max-width: 85vw;
  }

  .ep-login-btn {
    max-width: 85vw;
    margin: 16px 0 20px 46px;
    outline: none;
  }

  .ep-login-frm > p {
    margin-left: 84px !important;
  }
}

@media screen and (max-width: 600px) {
  .ep-login-vbge {
    padding: 0;
  }
  .ep-login-frm {
    padding: 0;
  }
}

.ep-lic-verbiage {
  width: 82%;
  margin: 0 auto;
}

.ep-lic-container{
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.redlink {
  color: red;
  background-color: transparent;
  text-decoration: none;
}

.boldtext {
  font-weight: 1000;
}
.nfDiv > h2 {
  text-align: center;
}

.nfDiv > h3 {
  text-align: center;
}

.nfDiv > a {
  text-align: center;
}

.nfDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formLineTempLicense {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;

}

.formLineTempLicense > select {
  margin-left: 10px;
  margin-right: 10px;
  width: 384px;
  height: 30px;
}

.formLineTempLicense > input {
  margin-left: 10px;
  margin-right: 10px;
  width: 50px;
  height: 20px;
}

.usersPar {
  margin-left: 5px !important;
  margin-right: 10px !important;
}

.formLineTempLicense > p {
  margin: 0px 0px;
}

.formLineTempLicense > button {
  height: 25px;
  margin-top: 0px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}


#selectDays {
  width: 60px;
}

.postContainer {
  width: 82%;
  margin: 0 auto;
}

.licensesContainer {
  width: 82%;
  margin: 0 auto;
}

