form > a {
  margin:15px;
}

.ep-login-grid {
  padding: 0 15px 0 15px;
  display: grid;
  grid-template-columns: auto minmax(min-content, 683px) minmax(min-content, 683px) auto;
  grid-template-rows: auto;
}

.forgot-pwd-button {
  grid-row-start: 2;
  grid-row-end: 2;
  grid-column-start: 3;
  grid-column-end: 3;
  padding: 0 56px 0 56px;
}

.ep-login-vbge {
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 2;
  grid-column-end: 2;
  padding: 0 56px 0 56px;
}

.ep-login-vbge > p, .ep-login-vbge > h2, .ep-login-frm > h3 {
  margin: 15px 0 4px 0px !important;
}

.ep-login-frm {
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 3;
  grid-column-end: 3;
  padding: 0 56px 0 56px;
}

.ep-login-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  margin-top: 15px;
}

.ep-login-item {
  flex: 0 1 auto;
  padding: 0;
  width: 70%;
}

.ep-login-item > p, .ep-login-item > input {
  margin: 5px 0 5px 0px !important;
}

.ep-login-input {
  width: 96%;
  font-size: 14px;
}

.ep-login-btn {
  display: 'flex';
  font-size: 14px;
  box-shadow: 1px 2px 3px grey;
  width: 157px;
}

.ep-login-frm > p {
  margin-left: 138px !important;
  font-size: 12px !important;
}

.fpb, .loginBtn {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 70%;
    margin-top: 15px;
}

@media screen and (max-width: 960px) {
    .ep-login-item > p, .ep-login-item > input {
    margin: 0px 0 8px 0px !important;
  }
  .ep-login-vbge {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .ep-login-frm {
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .forgot-pwd-button {
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .ep-login-row{
    flex-direction: column;
    width: 80%;
  }

  .ep-login-input {
    max-width: 85vw;
  }

  .ep-login-btn {
    max-width: 85vw;
    margin: 16px 0 20px 46px;
    outline: none;
  }

  .ep-login-frm > p {
    margin-left: 84px !important;
  }
}

@media screen and (max-width: 600px) {
  .ep-login-vbge {
    padding: 0;
  }
  .ep-login-frm {
    padding: 0;
  }
}
