@font-face {
  font-family: myNexaBoldFont;
  src: url(../assets/nexa/NexaBold.otf);
}

.ep-title {
  width: 100%;
  max-width: 1500px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ep-title > button {
  font-family: myNexaBoldFont;
  font-size: 38px;
  text-align: center;
  margin-left: 60px;
  margin-top: 14px;
  font-weight: bold;
  color: #6172b6;
  border: none;
  background: none;
  cursor: pointer;
}

.ep-header {
  flex: 0 1 auto;
}

.ep-header-grid {
  padding: 0 71px;
  display: flex;
  align-items: center;
  height: 120px;
}

.ep-logo-container {
  width: 200px;
  min-width: 200px;
}

.ep-stripe {
  height: 8px;
  background: #36b15b;
  width: 100vw;
  margin-bottom: 90px;
}

.ep-menu-container {
  display: flex;
  align-items: center;
  align-self: flex-end;
  margin-left: 5%;
}

.ep-welcome {
  display: inline-block;
  text-align: center;
  align-self: flex-end;
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 4;
  grid-column-end: 4;
  color: #7e57c5;
  padding-bottom: 1.5em;
  margin-left: 4%;
}
