.ep-menu {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: row;
}

.ep-menu-item {
  display: inline-block;
  align-self: flex-end;
  text-decoration: none;
  padding: 0 1em 1.5em 1em;
  outline: none;
}

.ep-menu-item > button {
  font-weight: normal;
  font-size: 16px;
}

.ep-menu-item > button:hover {
  color: #3f2b56 !important;
}

@media all and (max-width: 960px) {
  .ep-menu {
    justify-content: flex-start;
  }

  .ep-menu-item {
    padding: 1em 1em 1em 1em;
  }
}

@media all and (max-width: 600px) {
  .ep-menu {
    flex-direction: column;
    padding-bottom: 15px;
  }

  .ep-menu-item {
    padding: 0;
    align-self: flex-start;
  }
}
